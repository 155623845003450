import { AsyncPipe } from '@angular/common';
import { ChangeDetectionStrategy, Component } from '@angular/core';
import { Observable } from 'rxjs';
import { PageComponent } from '../common/page/page.component';
import { CampaignsResponse } from '../models/response-models';
import { AdminPortalApiService } from '../services/admin-portal-api.service';

@Component({
  selector: 'campaign-results',
  templateUrl: './campaign-results.component.html',
  standalone: true,
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [PageComponent, AsyncPipe],
})
export class CampaignResultsComponent {
  protected campaigns$: Observable<CampaignsResponse>;

  constructor(private apiService: AdminPortalApiService) {
    this.campaigns$ = this.apiService.getCampaigns();
  }
}
