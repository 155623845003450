import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from '../../environments/environment';
import { CampaignsResponse } from '../models/response-models';

@Injectable({
  providedIn: 'root',
})
export class AdminPortalApiService {
  private baseUrl: string = environment.api.serverUrl;

  constructor(private httpClient: HttpClient) { }

  public getOrganisations(
  ): Observable<any> {
    return this.httpClient.get<any>(
      `${this.baseUrl}/api/v1/organisations`,
    );
  }

  public getCampaigns(
  ): Observable<CampaignsResponse> {
    return this.httpClient.get<CampaignsResponse>(
      `${this.baseUrl}/api/v1/campaigns`,
    );
  }

}
