<app-page [content]="content" />

<ng-template #content>
  <h2 class="m-b-3">Campaign assessment results</h2>

  @for (item of (campaigns$ | async)?.campaigns; track item.name) {
    <li>{{ item.name }}</li>
    <li>{{ item.code }}</li>
    } @empty {
    <li>There are no items.</li>
    }
</ng-template>
