<app-page [content]="content" />

<ng-template #content>
  <h1 class="text-bold m-t-5 m-b-3">Admin centre dashboard</h1>

  <p>Welcome to the Admin Centre, you are logged in as '{{(user$ | async)?.email}}'.</p>

  <campaign-results></campaign-results>

</ng-template>
