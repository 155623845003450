import { Component, Inject } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AuthService } from '@auth0/auth0-angular';
import { PageComponent } from '../common/page/page.component';
import { DOCUMENT } from '@angular/common';

@Component({
  selector: 'login',
  standalone: true,
  imports: [CommonModule, PageComponent],
  templateUrl: './login-page.component.html',
})
export class LoginPageComponent {
  constructor(
    @Inject(DOCUMENT) public document: Document,
    private auth: AuthService) {

  }
}
